import {
  UilClipboardBlank,
  UilListUl,
  UilKeyholeCircle,
  UilAt,
  UilClock,
  UilUsersAlt,
  UilExternalLinkAlt,
  UilStar,
  UilArchive,
  UilFolder,
  UilPlusCircle,
  UilCheck,
  UilTimes,
  UilArrowLeft,
  UilAngleDown,
  UilAngleUp,
  UilFolderSlash,
  UilRefresh,
  UilFileInfoAlt,
  UilPlus,
  UilGoogleDriveAlt,
  UilFilter,
  UilTrash
} from '@iconscout/react-unicons';
import { UisStar } from '@iconscout/react-unicons-solid';
import { Tooltip } from 'react-tooltip';
import Axios from 'axios';
import CustomButton from 'components/custom/customButton';
import CustomDialog from 'components/custom/customDialog';
import CustomToggle from 'components/custom/customToggle';
import { CustomSelect } from 'components/customselect/customselect';
import {
  AuthContext,
  Button,
  CustomDropdown,
  CustomSearch,
  CustomTable,
  Expander,
  Loader,
  PageTitle,
  TextInput,
  UpgradePlan,
  useAPI,
  useRouteCheck,
  WhitelabelContext,
} from 'components/lib';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import './lists.scss';
import CustomModal from 'components/custom/customModal';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';
import axios from 'axios';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

function CreateFolder(props) {
  const [folderName, setFolderName] = useState('');

  async function createFolder() {
    try {
      if (folderName.length < 3) {
        Swal.fire({
          icon: 'error',
          title: 'Please Enter a valid folder name',
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066FF',
        })
          .then((result) => {})
          .catch((err) => {
            console.log('Error in saving ', err);
          });
        return;
      }

      await Axios.post(`/api/list/folders`, {
        name: folderName,
      });
      if (props.onCreate) props.onCreate();
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error in creating new folder',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      })
        .then((result) => {})
        .catch((err) => {
          console.log('Error in saving ', err);
        });
    }
  }

  return (
    <div>
      <h1 className="text-modal-header">Create New Folder</h1>
      <p className="text-modal-description">
        Organize your sequences, templates or lists in folders.
      </p>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <TextInput
          required={true}
          label="Folder Name"
          value={folderName}
          onChange={(name, value) => {
            setFolderName(value);
          }}
          placeholder="Enter a Folder Name"
        />
      </div>
      <Row
        justify="flex-end"
        style={{ gap: 20, marginInline: 0, paddingInline: 16, marginTop: 32 }}
      >
        <Button
          id='folderButtons'
          action={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
          text="Cancel"
          variant="primary grey"
        />
        <Button
          id='folderButtons'
          action={() => createFolder()}
          text="Create Folder"
          variant="primary"
        />
      </Row>
    </div>
  );
}

function MoveToFolder(props) {
  const [folderID, setFolderID] = useState('');
  const [folderName, setFolderName] = useState('');

  async function moveToFolder() {
    try {
      if (folderID.length < 3) {
        Swal.fire({
          icon: 'error',
          title: 'Please Select a folder',
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066FF',
        });
        return;
      }

      await Axios.patch(`/api/list/${props.listID.join(',')}`, {
        folder: folderID,
      });
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error in creating new folder',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    }
  }

  return (
    <div>
      <div>
        <h1 className='text-2xl text-modal-header'>Move List(s) to Folder</h1>
        <p className='text-modal-description'>Moving {props.listID.length} List(s) to Folder</p>
      </div>
      <div style={{ marginLeft: 15, marginRight: 15 }}>
        <Select
          placeholder="Select Folder"
          defaultValue={folderID}
          options={props?.folders?.map((userObj) => ({
            label: userObj?.name,
            value: userObj?.id,
          }))}
          onChange={(selected) => {
            setFolderID(selected?.value);
            setFolderName(selected?.label)
          }}
        />

        <Row justify="flex-end" style={{ margin: '32px 0px 0px', gap: 20 }}>
          <Button
            id='folderButtons'
            variant="primary grey"
            text="Cancel"
            action={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
          />
          <Button id='folderButtons' variant="primary" text={`Move ${folderName ? "to " + folderName : ""}`} action={() => moveToFolder()} />
        </Row>
      </div>
    </div>
  );
}

const ViewDetails = ({ detailsData }) => {
  // console.log(detailsData);
  const { contacts_count, id, name, last_modified } = detailsData;
  const sheetId = detailsData?.google_spreadsheet || '';
  const copyText = (props) => {
    window.navigator.clipboard.writeText(props);
    Toast.fire({ icon: 'success', title: 'Id code copied successfully' });
  };

  const showFormatedDate = (value) => {
    const date = DateTime.fromISO(value);

    const day = date.day;
    const suffix =
      day % 10 === 1 && day !== 11
        ? 'st'
        : day % 10 === 2 && day !== 12
        ? 'nd'
        : day % 10 === 3 && day !== 13
        ? 'rd'
        : 'th';

    return date.toFormat(`d`) + suffix + date.toFormat(` MMM yyyy`);
  }

  return (
    <>
      <div id='hide-in-mobile' className="list-details-container">
        <div className="list-details">
          <div>
            <span
              style={{
                '--accent-color': '#ED317F',
                '--border-color': '#F484B2',
                '--background-color': '#FBD6E5',
              }}
            >
              <UilListUl size={30} />
            </span>
            List Name
          </div>
          <div>
            <span
              style={{
                '--accent-color': '#8929E0',
                '--border-color': '#9F52E6',
                '--background-color': '#E3CCF8',
              }}
            >
              <UilKeyholeCircle size={30} />
            </span>
            List ID
          </div>
          {sheetId && 
            <div>
              <span
                style={{
                  '--accent-color': '#03A6FF',
                  '--border-color': '#93CCF3',
                  '--background-color': '#EAF1F7',
                }}
              >
                <UilGoogleDriveAlt size={30} />
              </span>
              Google Sheet ID
            </div>
          }
          <div>
            <span
              style={{
                '--accent-color': '#03A6FF',
                '--border-color': '#93CCF3',
                '--background-color': '#EAF1F7',
              }}
            >
              <UilAt size={30} />
            </span>
            Total Contacts
          </div>
          <div>
            <span
              style={{
                '--accent-color': '#F9C00C',
                '--border-color': '#FBD355',
                '--background-color': '#F3E8C4',
              }}
            >
              <UilClock size={30} />
            </span>
            Last Updated
          </div>
        </div>
        <div className="list-details-values">
          <div>{name}</div>
          <div>
            {id}
            <UilClipboardBlank
              style={{
                cursor: 'pointer',
                border: '1px solid currentColor',
                color: '#03A6FF',
                borderRadius: 5,
                padding: '1px',
              }}
              size={20}
              onClick={() => {
                copyText(id);
              }}
            />
          </div>
          {sheetId && 
            <div>
              <a target='_blank' href={`https://docs.google.com/spreadsheets/d/${sheetId}`} className='csCustomeLink inline-flex'>{sheetId} <UilExternalLinkAlt className="csLinkIcon" size={16} /></a>
            </div>
          }
          <div>{contacts_count}</div>
          <div>{showFormatedDate(last_modified)}</div>
        </div>
      </div>
      <div id='show-in-mobile' className='flex flex-col gap-4'>
        <div className='flex items-center gap-4'>
          <span style={{ padding:5, borderRadius:5, border:'1px solid #F484B2', backgroundColor:'#FBD6E5', color:'#ED317F' }}><UilListUl width={30} height={30} /></span>
          <div className='flex flex-col'><strong>List Name</strong> <p>{name}</p></div>
        </div>
        <div className='flex items-center gap-4'>
          <span style={{ padding:5, borderRadius:5, border:'1px solid #9F52E6', backgroundColor:'#E3CCF8', color:'#8929E0' }}><UilKeyholeCircle width={30} height={30} /></span>
          <div className='flex flex-col'>
            <strong>List ID</strong> 
            <p className='flex gap-2'>{id}
              <UilClipboardBlank
                style={{
                  cursor: 'pointer',
                  border: '1px solid currentColor',
                  color: '#03A6FF',
                  borderRadius: 5,
                  padding: '1px',
                }}
                size={20}
                onClick={() => {
                  copyText(id);
                }}
              />
            </p>
          </div>
        </div>
        {sheetId && <div className='flex items-center gap-4'>
          <span style={{ padding:5, borderRadius:5, border:'1px solid #93CCF3', backgroundColor:'#EAF1F7', color:'#03A6FF' }}><UilGoogleDriveAlt width={30} height={30} /></span>
          <div className='flex flex-col'><strong>Google Sheet ID</strong> <div>
              <a target='_blank' href={`https://docs.google.com/spreadsheets/d/${sheetId}`} className='csCustomeLink inline-flex'>{sheetId} <UilExternalLinkAlt className="csLinkIcon" size={16} /></a>
            </div>
          </div>
        </div>}
        <div className='flex items-center gap-4'>
          <span style={{ padding:5, borderRadius:5, border:'1px solid #93CCF3', backgroundColor:'#EAF1F7', color:'#03A6FF' }}><UilAt size={30} /></span>
          <div className='flex flex-col'><strong>Total Contacts</strong> <p>{contacts_count}</p></div>
        </div>
        <div className='flex items-center gap-4'>
          <span style={{ padding:5, borderRadius:5, border:'1px solid #FBD355', backgroundColor:'#F3E8C4', color:'#F9C00C' }}><UilClock width={30} height={30} /></span>
          <div className='flex flex-col'><strong>Last Updated</strong> <p>{showFormatedDate(last_modified)}</p></div>
        </div>
      </div>
    </>
  );
};

export default function Lists(props) {
  const [search, setSearch] = useState('');
  const [selectAll, setSelectAll] = useState([]);
  const [data, setData] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchFolder, setSearchFolder] = useState('');
  const [users, setUsers] = useState([]);
  const [folders, setFolders] = useState([]);
  const [searchOwnedBy, setSearchOwnedBy] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [showStarred, setShowStarred] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [moveToFolder, setMoveToFolder] = useState(false);
  const [details, setDetails] = useState(false);
  const [detailsData, setDetailData] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isSorted, setIsSorted] = useState('last_modified');
  const [isSortedDesc, setIsSortedDesc] = useState(true);
  const [pageTitle, setPageTitle] = useState('');
  const [isloading,setIsloading] =useState(true)
  const authContext = useContext(AuthContext);
  let navigator = useNavigate();
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [showDeleteListModal, setShowDeleteListModal] = useState(false);
  const deviceWidth = useWindowResize();

  const auth = useAPI('/api/auth');
  const allowRoute = useRouteCheck(
    window.location.pathname,
    auth.data?.plan,
    auth.data?.subscription,
    auth.data?.expired
  );

  useEffect(() => {
    setSearchOwnedBy(authContext.user.id)
  }, [])

  useEffect(() => {
    setPage(0)
  }, [searchFolder])

  useEffect(() => {
    let searchParams = new URLSearchParams();
    searchParams.append('limit', perPage);
    searchParams.append('skip', page);
    searchParams.append('sortBy', isSorted);
    searchParams.append('sortType', isSortedDesc ? 'DESC' : 'ASC');
    searchParams.append('search', search)
    if (showArchived) searchParams.append('archived', true);
    if (showStarred) searchParams.append('starred', true);
    if (searchFolder?.length > 0) searchParams.append('folder', searchFolder);
    if (searchOwnedBy?.length > 0) searchParams.append('userID', searchOwnedBy);

    fetchLists(searchParams.toString());
  }, [
    showArchived,
    showStarred,
    searchFolder,
    searchOwnedBy,
    perPage,
    page,
    isSorted,
    isSortedDesc,
    search
  ]);

  useEffect(() => {
    fetchUsers();
    fetchFolders();
  }, []);

  async function fetchUsers() {
    try {
      let result = await Axios.get(`/api/users`);
      setUsers(result?.data?.data?.map((userObj) => ({
        label: userObj?.name,
        value: userObj?.id,
      })));
      setIsOwner(result?.data?.isOwner);
    } catch (err) {
      console.log('Error in Fetching List! ', err);
    }
  }

  async function deleteFolder(folderID) {
    try {
      await Axios.delete(`/api/lists/folders/${folderID}`);
      fetchFolders();
    } catch (err) {
      console.log('Error in Fetching List! ', err);
    }
  }

  async function fetchLists(searchQuery) {
    setIsloading(true)
    try {
      let url = '/api/list';
      if (searchQuery?.length > 0) {
        url += '?' + searchQuery;
      }
      let result = await Axios.get(url);
      setData(result.data.data);
      // console.log(result.data.data);
      setListCount(result.data.count);
      setTotalPages(Math.ceil(result.data.count / perPage));
    } catch (err) {
      console.log('Error in getting data ', err);
    }
    setIsloading(false)
  }

  async function fetchFolders() {
    try {
      let url = '/api/lists/folders';
      let result = await Axios.get(url);
      setFolders(result?.data?.data || []);
    } catch (err) {
      console.log('Error in getting data ', err);
    }
  }

  async function updateList(id, body) {
    try {
      await Axios.patch(`/api/list/${id}`, body);

      let searchParams = new URLSearchParams();
      searchParams.append('limit', perPage);
      searchParams.append('skip', page);
      searchParams.append('sortBy', isSorted);
      searchParams.append('sortType', isSortedDesc ? 'DESC' : 'ASC');
      if (showArchived) searchParams.append('archived', true);
      if (showStarred) searchParams.append('starred', true);
      if (searchFolder?.length > 0) searchParams.append('folder', searchFolder);
      if (searchOwnedBy?.length > 0) searchParams.append('userID', searchOwnedBy);

      fetchLists(searchParams.toString());
      
      return true;
    } catch (err) {
      console.log('Error in updating list! ', err);
      return false;
    }
  }

  const starOrUnstar = (id, value, bulk) => {
    if (!id || id.length === 0 || !value) return;
    if (value === 'star') {
      updateList(id.join(','), {
        starred: true,
      });
    } else if (value === 'unstar') {
      updateList(id.join(','), {
        starred: false,
      });
    }
    setData((oldData) => {
      let newData = oldData.map((oldDataItem) => {
        if (id.find((i) => i === oldDataItem.id))
          oldDataItem.starred = value === 'star' ? true : false;
        return oldDataItem;
      });
      if (showStarred) {
        newData = newData.filter((oldDataItem) => {
          if (id.find((i) => i === oldDataItem.id)) return false;
          return oldDataItem;
        });
      }
      return newData;
    });
  };

  const archiveList = (id, value, bulk) => {
    if (!id || id.length === 0) return;
    updateList(id.join(','), {
      archived: value,
    });
    setData((oldData) => {
      let newData = oldData.filter((oldDataItem) => {
        if (id.find((i) => i === oldDataItem.id)) return false;
        return oldDataItem;
      });
      return newData;
    });
  };

  const removeFromFolder = (id) => {
    if (!id || id.length === 0) return;
    updateList(id.join(','), {
      folder: "",
    });
    setData((oldData) => {
      let newData = oldData.map((oldDataItem) => {
        if (id.find((i) => i === oldDataItem.id))
          oldDataItem.folder = "";
        return oldDataItem;
      });
      return newData;
    });
  };

  const columns = [
    {
      Header: (
        <>
          {!!data.filter((list) =>
            list.name.toLowerCase().includes(search.toLowerCase())
          ).length && (
            <CustomDropdown
              type="select"
              disabled={selectAll.length === 0}
              checked={selectAll.length === data.length}
              indeterminate={
                selectAll.length > 0 && selectAll.length < data.length
              }
              selectAction={() => {
                if (selectAll.length === data.length) setSelectAll([]);
                else if (selectAll.length > 0 && selectAll.length < data.length)
                  setSelectAll([]);
                else {
                  let selectArr = [];
                  for (let i = 0; i < data.length; i++) {
                    selectArr.push(data[i].id);
                  }
                  setSelectAll(selectArr);
                }
              }}
              items={[
                {
                  title: 'Star',
                  icon: <UisStar size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    starOrUnstar(selectAll, 'star', true);
                  },
                },
                {
                  title: 'Unstar',
                  icon: <UilStar size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    starOrUnstar(selectAll, 'unstar', true);
                  },
                },
                {
                  title: searchFolder ? "Remove from folder" : 'Move to Folder',
                  icon: searchFolder ? <UilFolderSlash size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UilFolder size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    if(searchFolder) removeFromFolder(selectAll)
                    else setMoveToFolder(true);
                  },
                },
                {
                  title: !showArchived ? 'Archive' : 'Un-Archive',
                  icon: showArchived ? <UilRefresh size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UilArchive size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    if (showArchived) archiveList(selectAll, false, true);
                    else archiveList(selectAll, true, true);
                  },
                },
                ...(showArchived ? [
                  {
                    title: 'Delete',
                    icon: <UilTrash size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                    action: () => { 
                      const selectedLists = selectAll?.map(list => data?.find(singleList => singleList?.id === list));
                      setSelectedList(prev => selectedLists);
                      setShowDeleteListModal(true);
                     },
                  }
                ] : [])
              ]}
              align="left"
            />
          )}
        </>
      ),
      Footer: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { cells } = row;
        let id = cells[0].value;
        let isSelected = false;

        if (selectAll.find((item) => item === id)) isSelected = true;
        return (
          <input
            type="checkbox"
            className="csCheckbox"
            defaultChecked={isSelected}
            onChange={(e) => {
              if (selectAll.find((item) => item === id))
                setSelectAll(selectAll.filter((item) => item !== id));
              else setSelectAll([...selectAll, id]);
            }}
          />
        );
      }, // formatting date
    },
    {
      Header: '',
      Footer: '',
      accessor: 'starred',
      disableSortBy: true, // disable sorting for the column
      Cell: ({ row, value }) => {
        const { cells } = row;
        if (value)
          return <UisStar 
            size={18} 
            style={{ cursor: 'pointer', color: '#F9C00C' }}
            onClick={() => starOrUnstar([cells[0].value], 'unstar')} 
          />
        return <UilStar 
            size={18} 
            style={{ cursor: 'pointer', color: '#F9C00C' }}
            onClick={(e) => starOrUnstar([cells[0].value], 'star')}
          />
      }, // formatting date
    },
    {
      Header: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (isSorted === '' || isSorted !== 'name') setIsSorted('name');
            else if (isSorted === 'name') setIsSortedDesc(!isSortedDesc);
          }}
        >
          Name
          <span
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginTop: 1,
            }}
          >
            {isSorted === 'name' ? (
              isSortedDesc ? (
                <UilAngleDown />
              ) : (
                <UilAngleUp />
              )
            ) : (
              ''
            )}
          </span>
        </div>
      ),
      Footer: 'Name',
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <Link to={`/outreach/lists/${row.values.id}`} className="csCustomeLink">
            {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
            <span className="csLinkEmpty"></span>
          </Link>
        );
      },
    },
    {
      Header: <span className="csCenter">Contacts</span>,
      accessor: 'contacts_count',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='flex csCenter'>
            <div className='flex' id="show-total"><UilUsersAlt size={18} className="mr-1" style={{ marginTop: 2 }} /> {original.contacts_count}</div>
            <div className='flex ml-2' id="show-archived"><UilArchive size={18} className="mr-1" style={{ marginTop: 2 }} />{original.archived_contacts}</div>
            <Tooltip anchorSelect="#show-total" place="top"> Total Contacts</Tooltip>
            <Tooltip anchorSelect="#show-archived" place="top"> Archived Contacts</Tooltip>
          </div>
        )
      },
    },
    {
      Header: <span className="csCenter">Verification Stats</span>,
      accessor: 'verification_status',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { original } = row;
        if(!original.verification_required) return <div className='flex csCenter'>-</div>
        if(original.valid_contacts === 0 && original.risky_contacts === 0 && original.invalid_contacts === 0) return <div className='flex csCenter'>
          Verifying
        </div>
        return (
          <div className='flex csCenter'>
            <div className='flex' id="show-valid"><UilCheck color="#3AC569" size={18} className="mr-1" style={{ marginTop: 2 }} /> {original.valid_contacts}</div>
            <div className='flex ml-2' id="show-risky"><UilCheck color="#F9C00C" size={18} className="mr-1" style={{ marginTop: 2 }} />{original.risky_contacts}</div>
            <div className='flex ml-2' id="show-invalid"><UilTimes color="#E53A40" size={18} className="mr-1" style={{ marginTop: 2 }} />{original.invalid_contacts}</div>
            <Tooltip anchorSelect="#show-valid" place="top"> Valid Emails</Tooltip>
            <Tooltip anchorSelect="#show-risky" place="top"> Risky Emails</Tooltip>
            <Tooltip anchorSelect="#show-invalid" place="top"> Invalid Emails</Tooltip>
          </div>
        )
      },
    },
    {
      Header: <span className="csCenter">Actions</span>,
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { cells, original } = row;
        let starred = cells[1].value;
        let isArchived = cells[1].row.original.archived;
        let label = 'Star';
        if (starred) label = 'Unstar';
        
        return (
          <div className="csCenter">
            <span
              className="csIconFill"
              style={{ cursor: 'pointer', marginRight: 5 }}
            >
              <UilPlus
                size={16}
                id="add-leads"
                onClick={() => {
                  if(row?.original?.google_spreadsheet) {
                    Swal.fire({
                      icon: 'info',
                      reverseButtons: true,
                      showCancelButton: true,
                      cancelButtonText: 'Cancel', 
                      confirmButtonText: 'Open Sheet',
                      confirmButtonColor: '#0066ff',
                      title: 'Import from Google Sheet!',
                      text: `Add leads to your Google Sheet in order to add them to ${whitelabelDetails?.agencyName}.`
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.open(`https://docs.google.com/spreadsheets/d/${row?.original?.google_spreadsheet}`, '_blank');
                      }
                    });
                  }
                  else window.open(`${window.location.origin}/import-list/${cells[0].value}`, '_blank')
                }}
              />
            </span>
            <Tooltip anchorSelect="#add-leads" place="top">
              Add Leads
            </Tooltip>
            <CustomDropdown
              right={true}
              items={[
                {
                  key: label,
                  title: label,
                  icon: starred ? <UilStar size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UisStar size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    if (starred) starOrUnstar([cells[0].value], 'unstar');
                    else starOrUnstar([cells[0].value], 'star');
                  },
                },
                {
                  key: 'Move to Folder',
                  title: original.folder ? "Remove from Folder" : 'Move to Folder',
                  icon: original.folder ? <UilFolderSlash size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UilFolder size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    if(original.folder) removeFromFolder([cells[0].value])
                    else setMoveToFolder(cells[0].value);
                  },
                },
                {
                  key: 'Details',
                  title: 'Details',
                  icon: <UilFileInfoAlt size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    setDetails(true);
                    setDetailData(original);
                  },
                },
                {
                  key: isArchived ? 'Un-Archive' : 'Archive',
                  title: isArchived ? 'Un-Archive' : 'Archive',
                  icon: isArchived ? <UilRefresh size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UilArchive size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    archiveList([cells[0].value], !isArchived);
                  },
                },
                ...(isArchived ? [
                  {
                    key: 'Delete',
                    title: 'Delete',
                    icon: <UilTrash size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                    action: () => { 
                      setSelectedList(prev => [original])
                      setShowDeleteListModal(true);
                     }
                  }
                ] : [])
              ]}
              align="left"
            />
          </div>
        );
      },
    },
  ];

  if (!allowRoute) {
    if (auth.loading) return <Loader />;
    else return <UpgradePlan />;
  }

  const deleteListsAndContacts = async () => {
    try {
      if (!selectedList || !selectedList?.length) return Toast.fire({ title: 'Please select lists to delete!', icon: 'error' });
      const listIDs = selectedList?.map(list => list?.id);
      const data = { listIDs };
      const response = await axios.delete('/api/list-and-contacts', { data });
      if (response?.data?.success) {
        setShowDeleteListModal(false);
        setSelectedList(null);
        Toast.fire({ title: 'Deleted lists and its contacts.', icon: 'success' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return;
      }
      return Toast.fire({ title: response?.data?.message || 'Something went wrong!', icon: 'error' });
    } catch (error) {
      return Toast.fire({ title: 'Could not delete lists and its contacts.', icon: 'error' });
    }
  }

  return (
    <>
      <Helmet>
        <title>{`Lists | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      {showDeleteListModal && <CustomModal close={true} onClose={() => setShowDeleteListModal(false)}>
        <div style={{ padding: 10, textAlign: 'center' }}>
          <div>This would Permanently Delete {selectedList?.length} list(s).</div>
          <div className='grid mt-4 gap-2' style={{ maxHeight: 300, overflowY: 'auto', gridTemplateColumns: deviceWidth > 768 ? 'repeat(2, minmax(0, 1fr))' : 'repeat(1, minmax(0, 1fr))' }}>{selectedList.map(list => <p style={{ backgroundColor: '#e9e9e9', borderRadius: 4, paddingBlock: 6, paddingInline: 16, textAlign: 'left' }}>{list.name}</p>)}</div>
          <div
            style={{
              display: 'flex',
              marginTop: 32,
              gap: '2rem',
              justifyContent: 'end',
              width: '100%',
            }}
          >
            <Button
              className='custom-buttons'
              variant="primary grey"
              action={() => setShowDeleteListModal(false)}
              text="Cancel"
            />
            <Button
              className='custom-buttons'
              variant="primary red"
              action={() => deleteListsAndContacts()}
              text={'Delete'}
            />
          </div>
        </div>
      </CustomModal>}
      <Row>
        <Col md={3} className="listFilter">
          <div style={{ maxHeight: '70vh' }} className="filter">
            <CustomSearch
              value={search}
              onChange={(e) => {setPage(0); setSearch(e);}}
              placeholder={'Search Lists'}
            />
            <CustomToggle
              title="Starred"
              icon={<UilStar />}
              onChange={(starred) => {
                if (starred) {
                  setPage(0); 
                  setShowStarred(true);
                }
                else setShowStarred(false);
              }}
            />
            <CustomSelect
              icon={<UilFolder />}
              customClearAction={() => {
                setPageTitle("")
                setSearchFolder('');
              }}
              new={() => {
                setShowCreateFolder(true);
              }}
              onDelete={(folderID) => {
                deleteFolder(folderID);

                // take the user back to all folders when they delete the same folder they're in
                if (searchFolder === folderID) setSearchFolder('');
              }}
              onChange={(selectedFolder) => {
                if (selectedFolder?.value === 'All Folders') {
                  setPageTitle("")
                  setSearchFolder('');
                  return 
                }
                setSearchFolder(selectedFolder.value);
                setPageTitle(`Lists in Folder ${selectedFolder.label}`)
              }}
              default={{ label: 'Folders', value: 'All Folders' }}
              options={folders?.map((folderObj) => ({
                label: folderObj.name,
                value: folderObj.id,
              }))}
              style={{ width: '100%', marginLeft: 'auto' }}
            />
            {isOwner && (
              <Expander
                style={{ width: '100%' }}
                label="Owned By"
                icon={<UilUsersAlt />}
              >
                {
                  <Select
                    placeholder="Select User"
                    options={users}
                    value={users?.find((userObj) => userObj.value === searchOwnedBy) || null}
                    onChange={(selected) => {
                      setPage(0);
                      setSearchOwnedBy(selected.value);
                    }}
                  />
                }
              </Expander>
            )}
            <CustomToggle
              title="Archived"
              icon={<UilArchive />}
              onChange={(archived) => {
                if (archived) {
                  setPage(0);
                  setShowArchived(true);
                }
                else setShowArchived(false);
              }}
            />

            {showCreateFolder && (
              <CustomDialog>
                <CreateFolder
                  onClose={() => setShowCreateFolder(false)}
                  onCreate={() => {
                    fetchFolders();
                    setShowCreateFolder(false);
                  }}
                />
              </CustomDialog>
            )}
          </div>
        </Col>
        <Col xs={12} lg={9} style={{ gap: 200 }}>
          {moveToFolder && (
              <CustomDialog>
                <MoveToFolder
                  listID={selectAll.length > 0 ? selectAll : [moveToFolder]}
                  folders={folders}
                  onClose={() => setMoveToFolder(false)}
                  onSuccess={() => {
                    setMoveToFolder(false);
                  }}
                />
              </CustomDialog>
          )}
          {details && (
            <CustomModal
              close={true}
              onClose={() => setDetails(false)}
              title="List Details"
              style={{ padding: 24 }}
            >
              <ViewDetails detailsData={detailsData} />
            </CustomModal>
          )}
          <Row>
            <Col xs={12} lg={9} className='flex'>
              {pageTitle && <button
                style={{
                  marginTop: 15,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  outline: '3px solid #A0A0A0',
                  height: 40,
                  width: 40,
                  borderRadius: 5,
                }}
                className="mr-5 text-white"
                role="button"
                id="show-all-lists"
                onClick={() => {
                  setPageTitle("")
                  setSearchFolder('');
                }}
              >
                <UilArrowLeft size={25} color="#A0A0A0" />
              </button>}
              <Tooltip anchorSelect="#show-all-lists" place="top">
                View all Lists
              </Tooltip>
              <PageTitle
                title={pageTitle || "Lists"}
                description={pageTitle ? "" : "Lists contain uploaded leads along with custom data."}
                tooltip="Lists can be used for in Outreach Sequences"
                headerStyle={{ marginTop: 0 }}
              />
            </Col>
            <Col
              id='list-buttons'
              xs={12} lg={3}
              style={{
                marginBottom: 10,
              }}
            >
              <CustomButton
                id='filter-button-list'
                onClick={() => setFilterVisible(prev => !prev)}
                title={filterVisible ? 'Hide Filters' : 'View Filters'}
                variant="secondary"
                icon={filterVisible ? <UilTimes /> : <UilFilter />}
              />
              <CustomButton
                id='new-list-button'
                title="New List"
                icon={<UilPlusCircle />}
                className="layout-reverse"
                variant="primary"
                onClick={() => navigator('/import-list/new')}
              />
            </Col>
            <Col id='filter-button-list'>
              <div style={{ display: filterVisible ? 'block' : 'none', marginTop: '10px' }}>
                <div>
                  <CustomSearch
                    value={search}
                    onChange={(e) => {setPage(0); setSearch(e);}}
                    placeholder={'Search Lists'}
                  />
                  <CustomToggle
                    title="Starred"
                    icon={<UilStar />}
                    onChange={(starred) => {
                      if (starred) {
                        setPage(0);
                        setShowStarred(true);
                      }
                      else setShowStarred(false);
                    }}
                  />
                  <CustomSelect
                    icon={<UilFolder />}
                    customClearAction={() => {
                      setPageTitle("")
                      setSearchFolder('');
                    }}
                    new={() => {
                      setShowCreateFolder(true);
                    }}
                    onDelete={(folderID) => {
                      deleteFolder(folderID);

                      // take the user back to all folders when they delete the same folder they're in
                      if (searchFolder === folderID) setSearchFolder('');
                    }}
                    onChange={(selectedFolder) => {
                      if (selectedFolder?.value === 'All Folders') {
                        setPageTitle("")
                        setSearchFolder('');
                        return 
                      }
                      setSearchFolder(selectedFolder.value);
                      setPageTitle(`Lists in Folder ${selectedFolder.label}`)
                    }}
                    default={{ label: 'Folders', value: 'All Folders' }}
                    options={folders?.map((folderObj) => ({
                      label: folderObj.name,
                      value: folderObj.id,
                    }))}
                    style={{ width: '100%', marginLeft: 'auto' }}
                  />
                  {isOwner && (
                    <Expander
                      style={{ width: '100%' }}
                      label="Owned By"
                      icon={<UilUsersAlt />}
                    >
                      {
                        <Select
                          placeholder="Select User"
                          options={users}
                          value={users?.find((userObj) => userObj.value === searchOwnedBy) || null}
                          onChange={(selected) => {
                            setPage(0);
                            setSearchOwnedBy(selected.value);
                          }}
                        />
                      }
                    </Expander>
                  )}
                  <CustomToggle
                    title="Archived"
                    icon={<UilArchive />}
                    onChange={(archived) => {
                      if (archived) {
                        setPage(0);
                        setShowArchived(true);
                      }
                      else setShowArchived(false);
                    }}
                  />

                  {showCreateFolder && (
                    <CustomDialog>
                      <CreateFolder
                        onClose={() => setShowCreateFolder(false)}
                        onCreate={() => {
                          fetchFolders();
                          setShowCreateFolder(false);
                        }}
                      />
                    </CustomDialog>
                  )}

                  {moveToFolder && (
                    <CustomDialog>
                      <MoveToFolder
                        listID={selectAll.length > 0 ? selectAll : [moveToFolder]}
                        folders={folders}
                        onClose={() => setMoveToFolder(false)}
                        onSuccess={() => {
                          setMoveToFolder(false);
                        }}
                      />
                    </CustomDialog>
                  )}
                  {details && (
                    <CustomModal
                      close={true}
                      onClose={() => setDetails(false)}
                      title="List Details"
                      style={{ padding: 24 }}
                    >
                      <ViewDetails detailsData={detailsData} />
                    </CustomModal>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {!isloading ? (
              listCount > 0 ? (
                <CustomTable
                  hasFooter={false}
                  columns={columns}
                  data={data}
                  count={listCount}
                  perPage={perPage}
                  onClick={(row) => {}}
                  setPerPage={setPerPage}
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" style={{ margin: 'auto' }} viewBox="0 0 100 100">
                    <circle cx="52" cy="52" r="44" opacity=".35"></circle><circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle><ellipse cx="50.016" cy="50.026" fill="#d9eeff" rx="38.017" ry="38.026"></ellipse><path fill="#70bfff" d="M50.189,66.359c-3.776,0-6.66-1.4-8.651-4.203c-1.991-2.802-2.987-6.799-2.987-11.992 c0-5.191,0.996-9.152,2.987-11.881c1.991-2.728,4.875-4.093,8.651-4.093c3.776,0,6.659,1.365,8.651,4.093 c1.991,2.729,2.987,6.69,2.987,11.881c0,5.193-0.995,9.19-2.987,11.992C56.849,64.959,53.965,66.359,50.189,66.359z M50.189,60.784c1.711,0,2.979-0.847,3.806-2.544c0.825-1.696,1.239-4.387,1.239-8.076c0-3.687-0.414-6.342-1.239-7.964 c-0.826-1.622-2.095-2.435-3.806-2.435s-2.98,0.812-3.806,2.435c-0.826,1.622-1.239,4.277-1.239,7.964 c0,3.688,0.413,6.379,1.239,8.076C47.209,59.937,48.478,60.784,50.189,60.784z"></path><path fill="#0080FF" d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"></path>
                  </svg>
                  <div style={{ textAlign: 'center', marginTop: 10 }}>
                    No lists found
                  </div>
                  <div style={{ margin: 'auto' }}>
                    <CustomButton
                      title="New List"
                      icon={<UilPlusCircle />}
                      className="layout-reverse"
                      variant="primary"
                      onClick={() => navigator('/import-list/new')}
                    />
                  </div>
                </div>
              )
              ): <div
              className="csFormLoader"
              style={{ background: `rgba(255, 255, 255, 0.5)`,marginTop:"10rem"}}
            >
              <Loader />
            </div>}
            </Col>
          </Row>
        </Col>
        <Tooltip anchorSelect='[data-tooltip-show="true"]' place="bottom" />
      </Row>
    </>
  );
}
